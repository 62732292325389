@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-text: #f9f9f9;
  --title-size: 35px;
  --text-font: 'Josefin Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  color: rgba(247,247,247,255);
  background-color: rgba(17,17,17,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 480px) {
  html {
    max-width: 480px;
  }
}

html, body {
  overflow-x: hidden;
}
