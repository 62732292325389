.accordian__sections {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5vw;
}

.accordian__image {
  height: 150px;
  width: 150px;
  border-radius: 5px;
  /* box-sizing: border-box; */
  border: 0;
  outline: none;
  object-fit: cover;
}


.active__image {
  border: 2px solid white;
}

.accordian__content {
  /* display: flex; */
  /* flex-direction: column; */
  margin-top: 5vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 5vh 8vw;
  gap: 20px;
}

.accordian__item {
  display: flex;
  align-items: center;
  gap: 15px;
  /* font-weight: 300; */
}

.song__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#song__artist {
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  #accordian {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }

  .accordian__sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80vw;
    /* gap: 5vw; */
    /* margin: 5vh auto; */

  }

  .accordian__content {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    justify-content: flex-start;
    width: 85vw;
  }

  .accordian__image {
    height: 125px;
    width: 125px;
  }



}