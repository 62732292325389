.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh; 
  gap: 20vh;
}

.gallery__photos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;  
  gap: 3vw;
}

.gallery__photo {
  height: 50vh;
  width: 30vw;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px 5px 5px 5px;
}

.computer__gallery {
  background-color: #1d1e22;
  padding: 5vh 7vh;
  border-radius: 10px;;
}

.phone__gallery {
  display: none;
}

@media screen and (max-width: 480px) {

  .gallery {
    margin-top: 10vh; 
  }
  
  .computer__gallery {
    display: none;
  }

  .phone__gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 5vh;
  }

  .gallery__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    width: auto;
  }

  #phone__image {
    width: 80vw;
    height: 100vh;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;

  }

  .gallery__buttons {
    display: flex;
    justify-content: space-between;
    width: 20vh;
  }
  .gallery__button {
    background-color: transparent;
    width: 30px;
    height: 30px;
  }

  .gallery__buttons img {
    width: 100%;  
    height: auto; 
  }

}