.nav__bar {
  display: flex;
  margin-top: 3vh; 
  margin-left: 3vw;
  margin-right: 3vw;
  justify-content: space-between;
  
}

#nav__title {
  font-size: 40px;
  font-family: var(--text-font);
  font-weight: 300;
}

.nav__icons {
  display: flex;
  justify-content: space-between;
  width: 250px;

}
.nav__icon {
  width: 40px;
  height: 40px;
}

#donate__icon {
  width: 42px;
  height: 42px;
}

@media only screen and (max-width: 480px) {
  .nav__bar {
    margin: 3vh 4vw; 
    justify-content: space-between;
    align-items: center;
    gap: 6vw;
  }

  #nav__title {
    font-size: 30px;
    text-align: left;
    width: 100vw;
  
  }

  .nav__icons {
    width: 20vh;
  }

  .nav__icon {
    width: 25px;
    height: 25px;
  }

  #donate__icon {
    width: 27px;
    height: 27px;
  }

}



