#search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 5vw;
}

.search__title {
  margin-top: 7vw;
  font-size: 50px;
  text-align: right;
  margin-right: 160px; 
  font-weight: 300; 
}

.request__song__link {
  font-weight: 300;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#request__link {
  cursor: pointer;
  border-bottom: 1px solid #f9f9f9;  
}

.search__and__sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
}

#sort__by {
  font-weight: 300;
}

.search__and__clear {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search__input {
  font-size: 18px; 
  font-family: var(--text-font);
  padding-left: 20px;
  height: 40px;
  width: 300px;
  border: none;
  border-radius: 7px; 
  background-color: #1d1e22;
  color: #f9f9f9;
  outline: none; 
  transition: box-shadow 0.3s ease; 
  font-weight: 300;
}

.search-input::placeholder {
  color: #f9f9f9; 
  font-weight: 300;
}

.clear__icon {
  width: 15px;
  height: 15px;
}

.sort__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
}

.sort__dropdown {
  font-size: 18px; 
  font-weight: 300;
  text-align: center;
  background-color: #1d1e22;
  border-radius: 10px;
  width: 70px;
  height: 40px;
  align-items: center;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  position: relative;
}

.dropdown__content {
  font-size: 18px; 
  font-weight: 300;
  position: absolute;
  left: 106%;  
  top: 0%;
  background-color: #1d1e22;
  color:#f9f9f9;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.dropdown__item {
  padding-top: 5px;
}

.table__container {
  min-height: 500px;
  background-color: #1d1e22;
  padding: 30px 55px;
  border-radius: 7px;
  max-height: 70vh;
  overflow-y: auto;
}

table {
  width: 85vw;
  border-collapse: collapse;
}

th, td {
  width: 25vw;
  padding: 10px;
}

th {
  text-align: left;
  border-bottom: 1px solid #f9f9f9;
  font-size: 25px;
  font-weight: 300;
  padding-bottom: 20px;
  
}

.phone__header {
  display: none;
}

td {
  font-size: 18px;
  font-weight: 300;
}

.cover__title {
  display: flex;
  align-items: center;
  gap: 20px;
  width: auto;
  }

.album__cover {
  min-height: 50px;
  min-width: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 5px;
}


.phone__song {
  display: none;
}

.difficulty__circle {
  display: inline-block;
  width: 10px;  
  height: 10px; 
  border-radius: 50%; 
}

.difficulty__circle.easy {
  background-color: rgb(48, 183, 48); 
}

.difficulty__circle.medium {
  background-color: rgb(255, 172, 19); 
}

.difficulty__circle.hard {
  background-color: rgb(255, 58, 58); 
}


@media screen and (max-width: 480px) {

  .table__container {
    border-radius: 0;
    padding-left: 70vw;
  }

  td, th {
    width: 65vw;
  }

  .test__div {
    display: flex;
    gap: 15vw;
  }

  .search__container {
    width: 10vw;
  }

  .search__title {
    width: 95%;
    font-size: 40px;
    line-height: 50px;
    margin-top: 5vh;
    margin-left: 30vw;
  }

  .search__and__sort {
    gap: 2vh;
    width: 90vw;
    flex-direction: column;
    align-items: flex-start;
  }

  .search__input {
    width: 82vw;
  }

  .search__and__clear {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .dropdown__content {
    display: flex;
    gap: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .request__song__link {
    margin-top: 3vh;
    font-size: 16px;
  }

  table {
    min-width: 30vw;
  }

  thead,
  .computer__header,
  .computer__song {
    display: none;
  }
  
  .phone__header {
    display: initial;
  }

  .phone__song {
    display: flex;
    align-items: center;
    gap: 2vh;
  }

  .phone__song.difficulty__indicator {
    text-align: right; 
    vertical-align: middle; 
  }

  .title__artist {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
  }

  #phone__title {
    font-size: 18px;
    font-weight: 400;

    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    max-width: 50vw;
  }

  .artist__tags {
    display: flex;
    flex-direction: row;
  }

  #phone__artist,
  #phone__tags {
    font-size: 15px;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    max-width: 40vw;
  }
}