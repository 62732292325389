.landing__page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.landing__bg {
  background-image: url('../../assets/photos/maton2.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.landing__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: right;
  font-family: var(--text-font);
  margin-top: 75px;
  font-weight: 300; 
  z-index: 2;
  margin-left: 3%;
  text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.5); /* Adjust the values as needed */
}

#landing__quote {
  font-size: 80px;
  font-family: var(--text-font);
  width: 100%;
  margin-bottom: 50px;
}

.landing__highlight {
  position: relative;
  display: inline-block;
}

.landing__highlight::after {
  content: "";
  position: absolute;
  background-color: #f9f9f9;
  height: 15px;
  width: 0%; 
  bottom: -10%; 
  left: -3%;
  z-index: -1;
  transition: width 2s ease;
  animation: underline-slide 1s ease forwards; 
}

#landing__info {
  font-size: 30px;
  text-align: right;
  max-width: 90%;
  line-height: 40px;
  margin-bottom: 50px;
  font-weight: 300;
}

#landing__em {
  font-size: 30px;
  font-style: italic;
  margin-bottom: 50px;
}

.landing__icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  vertical-align: middle;
}

.landing__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  font-size: var(--title-size);
  margin: 0 auto;
  margin-bottom: 20px;
}

#book__btn {
  font-size: var(--title-size);
  font-family: var(--text-font);
  padding: 10px 15px 4px 15px;
  border-radius: 5px;
  font-weight: 300; 
  background-color: #f9f9f9;
  color: rgba(17,17,17,255);
}

.landing__browse {
  display: flex;
  align-items: center;
  width: 235px;
  font-weight: 300; 
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .landing__page {
    width: 100%;
  }

  .landing__text {
    margin-top: 3vh;
    align-items: flex-start;
  }

  #landing__quote {
    width: 50vh;
    font-size: 60px;
    text-align: left;
    line-height: 70px;
    margin-left: 3vw;
    margin-bottom: 3vh;
  }

  #landing__info {
    width: 40vh;
    text-align: left;
    margin-left: 5vw;
    font-size: 25px;
    margin-bottom: 4vh;
  }

  .landing__highlight {
    margin-bottom: 15px;
  }

  .landing__highlight::after {
    height: 13px;
    bottom: -7%;
    left: 0px;
    width: 100%;
  }

  .landing__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1vh;
    font-size: 30px;
  }

  #book__btn {
    font-size: 30px;
  }

  #landing__btn {
    width: 15vw;
  }
}

@keyframes underline-slide {
  from {
    width: 0%;
  }
  to {
    width: 105%;
  }
}

@media only screen and (max-width: 480px) {
  @keyframes underline-slide {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
}
